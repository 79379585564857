@import "../common/responsiveComponent";
.main-header {
  width: 100%;
  float: left;
  margin-right: 0px;
  margin-left: 0px;
  // box-shadow: 0px 7px 14px #cccccc;
  // z-index: 999;
  // margin-bottom: 2em;
  // -webkit-box-shadow: 0px 7px 14px #cccccc;
  // -moz-box-shadow: 0px 7px 14px #cccccc;
  .navbar {
    width: 99%;
    margin: 0 auto;
    padding: 25px 32px;
    background: transparent !important;
  }
  .navbar-brand {
    img {
      width: 95px;
    }
  }
  .navbar-section {
    padding: 0;
  }
}
.prelogin-main,
.postlogin-main {
  width: 89%;
  margin: 0 auto;
  float: none;
  .pre-textbox {
    padding: 26px 20px 0px 20px;
    border-bottom: 1px solid #f2f2f2;
    .text-muted {
      color: red !important;
      font-weight: 500;
      opacity: 0.8;
      line-height: 16px;
      font-size: 10px;
      margin-top: -9px !important;
      &::before {
        // content: "🡡";
        // font-size: 10px;
        // display: inline-block;
        // padding-right: 4px;
      }
    }
  }
  .prelogin-left {
    width: 100%;
    float: left;
    // margin: 2em 0;
    height: 92vh;
    .box-component {
      height: 74vh;
      float: left;
      clear: both;
      width: 100%;
    }
  }
  .prelogin-right {
    width: 42%;
    float: right;
    background: #000;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    box-shadow: inset 0px 0 15px #fff;
    .geo-rightimage {
      width: 100%;
      object-fit: cover;
      height: -webkit-fill-available;
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }
    .gs-img-code {
      width: 100%;
      object-fit: cover;
      height: -webkit-fill-available;
      filter: blur(12px);
    }
    .gs-qr {
      position: absolute;
      top: 40%;
      left: 40%;
      background: white;
      width: 21%;
      height: 19%;
      padding: 15px;
      z-index: 9999;
    }
    .map-icon-container {
      position: absolute;
      z-index: 10;
      margin: 60% 45%;
      border: 1px solid white;
      padding: 2%;
      border-radius: 50px;
    }

    @media screen and #{$tablet-portrait} {
      .map-icon-container {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 30%;
        width: 50%;
        margin: -15% 0 0 -25%;
        border: unset;
        padding: 2%;
        border-radius: 50px;
        text-align: center;
      }
    }
    @media screen and #{$large-tablet} {
      .map-icon-container {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 30%;
        width: 50%;
        margin: -15% 0 0 -25%;
        border: unset;
        padding: 2%;
        border-radius: 50px;
        text-align: center;
        div {
          svg {
            border-radius: 100px;
            border: 1.12px solid #ffffff !important;
            padding: 5px;
            box-sizing: border-box;
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }
  .gs-ignite {
    position: absolute;
    right: 37%;
    margin: 20px;
    z-index: 999;
    @media screen and #{$tablet-portrait} {
      right: 33% !important;
    }
    @media screen and #{$large-tablet} {
      right: 35% !important;
    }
  }

  .project-left {
    // height: 37em;
    position: relative;
    width: 100%;
    float: left;
    @media screen and #{$tablet-portrait} {
      .project-section {
        height: 58em;
      }
    }
  }
  @media only screen and #{$tablet-portrait} {
    .project-left {
      height: auto !important;
      position: initial !important;
      // width: 0 !important;
      float: none !important;
    }
  }
}

//postlogin changes
.postlogin-main {
  .prelogin-right {
    width: 42%;
    float: right;
    background: #000;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;
    -webkit-box-shadow: inset 0 0 17px #fff;
    -moz-box-shadow: inset 0 0 200px #fff;
    box-shadow: inset 0px 0 15px #fff;
    .geo-rightimage {
      width: 100%;
      object-fit: cover;
      height: -webkit-fill-available;
      -webkit-filter: blur(5px);
      filter: blur(5px);
      margin: 1px -14px -32px 1px;
    }
  }
}
.gs-footer {
  padding: 22em;
  @media screen and #{$tablet-portrait} {
    padding: 0;
  }
}

.loaderqr-container {
  position: absolute;
  left: 26%;
  top: 47%;
  div:nth-child(1) {
    position: absolute;
    left: 50%;
    top: 9em;
    bottom: 0;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1920px) {
    left: 30%;
  }
}
