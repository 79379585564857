@import "../../../../common/responsiveComponent";
//common css for progress items
$theme-color: #045ec8;
%common-css {
  color: $theme-color;
  font-weight: 500;
  border-bottom-color: $theme-color !important;
}

.andr-progress {
  @media screen and #{$tablet-portrait} {
    width: 55%;
  }
  .progress-content {
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    margin-bottom: 7px;
    margin: 10px 0 13px 0;
    .progress-steps {
      border-bottom: 2px solid #9c9c9c;
      border-radius: 2px;
      width: 18%;
      text-align: -webkit-center;
      padding-bottom: 6px;
    }
    .active {
      @extend %common-css;
    }
    @media screen and #{$tablet-portrait} {
      .progress-steps {
        width: 19%;
        text-align: center;
      }
    }
  }
}
.second-step {
  .progress-content {
    .step-2 {
      @extend %common-css;
    }
  }
}
.third-step {
  .progress-content {
    .step-2,
    .step-3 {
      @extend %common-css;
    }
  }
}
.forth-step {
  .progress-content {
    .step-2,
    .step-3,
    .step-4 {
      @extend %common-css;
    }
  }
}
.fifth-step {
  .progress-content {
    .step-2,
    .step-3,
    .step-4,
    .step-5 {
      @extend %common-css;
    }
  }
}
.sixth-step {
  .progress-content {
    .step-2,
    .step-3,
    .step-4,
    .step-5,
    .step-6 {
      @extend %common-css;
    }
  }
}
