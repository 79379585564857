.code-input-component {
  position: fixed;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10050;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  .gs-code-box {
    width: 61%;
    text-align: center;
    margin: auto;
    .gs-code-title {
      font-size: 25px !important;
      line-height: 36px;
    }
    .gs-email {
      color: #045ec8;
    }
  }
  .gs-code-email {
    margin-top: 22px !important;
    margin-bottom: 32px !important;
  }
  .gs-code-box {
    width: 61%;
    margin: auto;
    margin-bottom: 20px;
    .react-code-input {
      input {
        border-radius: 4px !important;
        border: 2px solid rgba(74, 74, 74, 0.7) !important;
        width: 47px !important;
        height: 57px !important;
        box-shadow: none !important;
        margin: 0px 20px 0px 0px !important;
        background-color: transparent !important;
        padding-left: 0px !important;
        text-align: center;
        &:focus {
          border: 2px solid #045ec8 !important;
          outline: none !important;
        }
      }
      .react-code-container {
        display: flex;
        &:nth-child(3) {
          & input + span:after {
            content: " ";
            display: inline-block;
            vertical-align: center;
            height: 2px;
            width: 20px;
            background: rgba(74, 74, 74, 0.7);
            margin-right: 15px;
            margin-top: 28px;
          }
        }
      }
    }
    img {
      margin-right: 15px;
      vertical-align: initial !important;
      height: 25px;
    }
    h1,
    a {
      color: #4a4a4a;
      font-weight: 500;
    }
    a:hover {
      text-decoration: none;
      color: #045ec8 !important;
    }
  }
  .gs-code-input {
    width: 100% !important;
    margin-bottom: 32px;
  }
  .gs-code-box-error {
    .react-code-input {
      input {
        border: 2px solid #cc0000 !important;
        outline: none !important;
      }
    }
  }
}

.code-input-component .gs-code-box .react-code-input input:nth-child(3)::after {
  content: "-";
  position: absolute;
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: red;
}
