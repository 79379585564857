.main-header {
  .navbar-brand {
    .main-geo-logo {
      width: 70px;

      height: auto;
      object-fit: contain;
    }
    .cyprinus-logo {
      width: 150px;
    }
  }
  .logo-title {
    font-size: 21px;
    font-weight: 500;
    padding: 5px;
  }
}
