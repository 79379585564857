$phone: "(max-width: 480px)";
$phone-landscape: "(max-width: 480px), (orientation: landscape)";
$tablet-portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation : portrait)";
$tablet-landscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$large-tablet: "(min-width: 1023px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

.gs-btn {
  width: 161px;
  height: 39px;
}

.gs-primary {
  background-color: #045ec8;
  border: none;
  color: #ffffff !important;
}

.gs-secondary {
  background-color: #ffffff;
  border: 1.2px solid;
  color: #56575e;
}

.gs-integrate-img {
  width: 100%;
  display: block;
  height: auto;
  margin: 14px 0;
}
.gs-code-component {
  .gs-code-section {
    overflow-y: auto;
    height: 23em;
  }
  .gs-code-btn {
    margin-top: 10px;
  }
}

@include respond-to($tablet-portrait) {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
  }
}

@include respond-to($large-tablet) {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
    margin-top: 10px;
  }
}
