@import "../common/_responsiveComponent";

.addmember-button {
  width: auto;
  @media only screen and #{$tablet-portrait} {
    width: auto;
    font-size: 0.9em;
    min-height: 30px;
    padding: 3px 13px !important;
    margin-top: 17px;
  }
  @media only screen and #{$large-desktop} {
    width: auto;
    font-size: 12px;
    min-height: 30px;
    padding: 2px 12px !important;
    margin-top: 17px;
  }
}
.addmember-button[disabled] {
  display: none;
}

.app-box-submit {
  background: #495fc3;
  color: #ffffff;
  border: #495fc3;
  padding: 6px 12px;
  border: 0px;
  border-radius: 4px;
  width: auto;
  font-size: 14px;
  outline: none;
  min-height: 34px;
  margin-top: 4px;
}

.app-box-cancel {
  background: #ffffff;
  color: #000000;
  border: #495fc3;
  padding: 6px 12px;
  border: 0px;
  border-radius: 4px;
  width: auto;
  font-size: 14px;
  outline: none;
  min-height: 34px;
  margin-right: 5px;
  margin-top: 4px;
}
.prelogin-main {
  .gs-btn {
    width: 161px;
    height: 39px;
  }
}

// .prelogin-button-fill {
//   background: $button-blue;
//   border: 1px solid $button-blue;
//   &:hover {
//     background: #495fc3;
//   }
// }

// .prelogin-button {
//   background: $button-noblue;
//   border: 1px solid $button-blue;
// }

.setting-title {
  position: relative !important;
  display: flex;
  // h5 {
  //   font-size: 14px;
  // }
  svg {
    right: 0px;
    top: 0px;
    fill: #fff;
    box-shadow: 0px 0px 8px 2px rgba(38, 80, 162, 0.3);
    border-radius: 20px;
    background-color: #045ec8;
    padding: 5px;
    width: 23px;
    height: 23px;
    margin-left: auto;
    margin-right: 29px !important;
  }

  @media only screen and #{$tablet-portrait} {
    svg {
      margin-right: 4px !important;
    }
  }
  @media only screen and #{$large-tablet} {
    svg {
      margin-right: 4px !important;
    }
  }
}

.setting-edit-icon-wrapper {
  margin-left: auto;
  .setting-edit-delete-custom-style {
    margin-right: 3px !important;
  }
}

.prelogin-main {
  .prelogin-left {
    .box-component {
      .gs-secondary {
        background-color: #ffffff;
        border: 2px solid #f2f2f2;
        color: #484848;
      }

      .divider {
        width: 420px;
        text-align: center;
        border-bottom: 2px solid #f2f2f2;
        line-height: 0.1em;
        margin: 10px 0 20px;
        font-size: 15px;
        font-weight: bold;
        span {
          background: #fff;
          padding: 0 10px;
        }
      }
    }
  }
}
