.textinput {
    border: 0px;
    font-size: 13px;
    color: #383838;
    padding: 0;
    background-color: #ffffff !important;
  }
  
  .text-indent {
    text-indent: -99999px;
  }
  
  

  .event-test-container {

    padding-right: 20px;
    padding-top: 20px;

    .event-test-title {
      color: #484848;
      font-size: 14px;
      letter-spacing: 0.12px;
    }
    .event-dropdown {
      padding-left: 20px;
      padding-bottom: 20px;

      h6 {
        font-size: 14px !important;
        color: #484848;
        font-weight: 500;
        margin-bottom: 0px !important;
      }

      .btn-primary {
        width: 100%;
        background-color: #fff;
        color: #4a4a4a;
        border-color: #fff;
        font-size: 13px;
        text-align: left;
        font-weight: 400;
        height: auto;
        padding: 0px;

        &::after {
          right: 15px;
          top: 11px;
          position: absolute;
          pointer-events: none;
          content: "";
          width: 17px;
          height: 16px;
          background-size: contain;
          float: right;
          margin: 0;
          border: 0;
        }
      }
    }
    .gs-btn-test {
      width: 100%;
      margin: 20px 0;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.12px;
    }
    .usersImage {
      padding-top: 15px;
      padding-right: 20px;
    }
  }



  