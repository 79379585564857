.gs-sdk-icon {
  img {
    border-radius: 100px;
    border: 1.12px solid #045ec8;
    padding: 5px;
    box-sizing: border-box;
    width: 27px;
    height: 27px;
  }
}
