@import "../../../../common/_responsiveComponent";

.gs-intro {
  font-size: 14px;
  display: block;
  padding: 1em 0;
}
.gs-span {
  font-size: 14px;
  display: block;
  line-height: 20px;
  color: #4a4a4a;
  padding: 14px 0;
}
.gs-publishkey {
  font-size: 14px;
  color: #ffffff;
  background: #045ec8;
  padding: 19px 21px;
  margin-bottom: 14px;
  display: block;
  border-radius: 3px;
  font-weight: 500;
  overflow: auto;
}

.gs-codesection {
  .nav-tabs {
    a {
      font-size: 13px;
      color: #045ec8;
    }
  }
  code {
    font-size: 13px;
  }
}
.gs-code-component {
  .gs-code-section {
    overflow-y: auto;
    height: 23em;
  }
  .gs-code-btn {
    margin-top: 10px;
    @media screen and #{$tablet-portrait} {
      display: flex;
      margin-top: 10px !important;
    }

    @media screen and #{$large-tablet} {
      display: flex;
      margin-top: 10px !important;
    }
  }
  .andr-btn {
    display: flex;
  }
}

@media screen and #{$tablet-portrait} {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
  }
}

@media screen and #{$large-tablet} {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
    margin-top: 10px;
  }
}
