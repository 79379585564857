body {
  margin: 0;
  padding: 0;
  // font-family: "Maven Pro", sans-serif;
  font-family: "Maven Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
  color: #202124;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #4a4a4a;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:focus {
  color: #383838;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}

.notification {
  background: #ffffff !important;
  border-top: 1px solid #ccc !important;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
    rgba(9, 30, 66, 0.25) 0px 20px 32px -8px !important;
  color: rgb(66, 82, 110);
  -webkit-box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
    rgba(9, 30, 66, 0.25) 0px 20px 32px -8px !important;
  -moz-box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
    rgba(9, 30, 66, 0.25) 0px 20px 32px -8px !important;
  width: 100%;
  z-index: 600;
  border-radius: 3px !important;
  transition: background-color 200ms ease 0s;
  padding: 18px !important;
  width: 400px !important;
  right: 119px !important;
  top: 20px !important;

  &.notification-success {
    .notification-title {
      margin-bottom: 0px !important;
      margin-top: -4px;
      &::before {
        content: "✓";
        color: #fff;
        background: rgb(94, 164, 0);
        padding: 5px 10px;
        float: left;
        border-radius: 3em;
        box-sizing: border-box;
        font-size: 13px;
        margin-right: 14px;
        line-height: 21px;
        width: 30px;
        height: 30px;
        position: relative;
        top: -6px;
      }
    }
  }
  &.notification-error {
    .notification-title {
      margin-bottom: 0px !important;
      color: #fa3e3e !important;
      margin-top: -4px;
      &::before {
        content: "✖";
        color: #fff;
        background: #fa3e3e;
        padding: 5px 10px;
        float: left;
        border-radius: 3em;
        box-sizing: border-box;
        font-size: 12px;
        margin-right: 14px;
        line-height: 21px;
        width: 30px;
        height: 30px;
        position: relative;
        top: -8px;
      }
    }
  }
}

.notification-dismiss {
  background-color: transparent !important;
  color: #ccc !important;
}
.gs-post-login {
  background-color: #f2f2f2;
  min-height: -webkit-fill-available;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  // .main-header {
  //   margin-bottom: 0em;

  //   // .navbar {
  //   //   padding: 0.5rem 1rem 0;
  //   //   box-shadow: 0px 7px 14px #cccccc;
  //   //   -webkit-box-shadow: 0px 7px 14px #cccccc;
  //   //   -moz-box-shadow: 0px 7px 14px #cccccc;
  //   //   z-index: 99999;
  //   // }
  //   // .navbar-section {
  //   //   position: fixed;

  //   //   z-index: 999;
  //   // }
  // }
}
.text-muted {
  color: red !important;
  opacity: 0.9 !important;
  font-size: 12px !important;
}
.tooltip {
  border-bottom: none !important;
  pointer-events: none !important;
  z-index: 9999;
}
.tooltip-inner {
  background-color: #404040;
  max-width: 100%;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #404040;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #404040;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #404040;
}
a {
  cursor: pointer;
}

.changed-input-field {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-radius: 5px;
}
