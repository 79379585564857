.sdk-integration-done {
  .project-info-box {
    width: 42%;
    height: 60%;
    border: 5px;
    margin: 30px 0px 40px 0px;
    padding: 20px;
    box-shadow: 3px 3px 10px 10px rgb(236, 236, 236);
    .box-set {
      display: flex;
      flex-direction: row;
      .box {
        // padding: 40px;
        // border: 10px;
        margin: 5px;
        border-width: thin;
        border: 1px solid rgb(216, 216, 216);
        width: 48%;
        // height: 50%;
        height: 101px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #56575e;
        text-align: center;
      }
    }
  }

  .done-button {
    margin: 30px 0px 40px 0px;
  }
}
