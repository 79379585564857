$primary-blue: #045ec8;
$primary-darkblue: #0454ae;
$primary-disabled: #d8d8d8;
$secondary-disabled-color: #56575e; //text color
$white-bg: #ffffff;
$secondary-hover-bg: rgba(38, 80, 162, 0.1);
$danger-bg: #d22435;
$danger-hover-bg: #bb2d3b;

.gs-btn {
  width: 170px;
  height: 43px;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 5px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-size: 13.1px;
  &:focus,
  &:active {
    outline: none !important;
  }
}
.gs-primary {
  background-color: $primary-blue;
  border: none;
  color: $white-bg !important;
  &:hover {
    background-color: $primary-darkblue;
  }
}
.gs-secondary {
  background-color: $white-bg;
  border: 1.2px solid $primary-blue;
  color: $secondary-disabled-color;
  &:hover {
    background-color: $secondary-hover-bg;
    color: $primary-blue !important;
    border: 1.2px solid $primary-blue !important;
  }
  &:focus,
  &:active {
    border: 1px solid $white-bg !important;
    color: $white-bg !important;
    background-color: $primary-blue !important;
    box-shadow: none !important;
  }
}
.gs-disabled {
  background-color: $primary-disabled;
  // box-shadow: 0px 0px 17px 8px $secondary-disabled-color;
  cursor: not-allowed !important;
  color: $white-bg !important;
  border: none !important;
  -webkit-box-shadow: 0px 2px 21px 7px rgba(86, 87, 94, 0.12);
  -moz-box-shadow: 0px 2px 21px 7px rgba(86, 87, 94, 0.12);
  box-shadow: 0px 2px 21px 7px rgba(86, 87, 94, 0.12);
}
.gs-price-btn {
  border: 1px solid $primary-blue;
  color: #ffffff;
  background-color: $primary-blue;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.05) inset;
  width: 100% !important;
  margin-top: 3em;
  height: 50px !important;

  &:hover {
    background-color: #ffffff;
    color: $primary-blue;
  }
}
.gs-active-btn {
  background-color: #d8d8d8;
  color: #404040;
  border: 1px solid #d8d8d8;
  &:hover {
    background-color: #d8d8d8;
    color: #404040;
    border: 1px solid #d8d8d8;
  }
}
.gs-danger,
.gs-danger-primary {
  background-color: $danger-bg !important;
  border: none;
  color: $white-bg !important;
  &:hover {
    background-color: $danger-hover-bg !important;
  }
}
