.loadertitle {
  position: absolute;
  z-index: 10000;
  color: #000000;
  align-items: center;
  justify-content: center;
  width: 385px;
  font-size: 15.5px;

}
.loader {
  border: 1px solid #000000;
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  z-index: 10000; 
  position: absolute;
  left: -2em;
  top: 0.2em;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
