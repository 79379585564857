@import "../responsiveComponent";
$theme-color: #045ec8;

%common-css {
  color: $theme-color;
  font-weight: 500;
  border-bottom-color: $theme-color !important;
}

.progressBar {
  width: 48%;
  margin-top: 40px;
  margin-bottom: 20px;
  float: left;

  @media only screen and #{$tablet-portrait} {
    width: 55%;
  }
  .progress-content {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    margin-bottom: 7px;
    margin: 10px 0 13px 0;
    .progress-items {
      border-bottom: 2px solid #9c9c9c;
      border-radius: 2px;
      width: 24%;
      text-align: -webkit-center;
      padding-bottom: 6px;
    }
    .active {
      @extend %common-css;
    }
    @media only screen and #{$tablet-portrait} {
      .progress-items {
        width: 10%;
        text-align: center;
      }
    }
    // .gs-integrate {
    //   @extend %common-css;
    // }
    // .gs-create {
    //   @extend %common-css;
    // }
  }

  @media screen and #{$large-tablet} {
    .progress-content {
      bottom: 0;
    }
  }

  @media screen and #{$tablet-portrait} {
    .progress-content {
      bottom: 0;
      position: absolute;
    }
  }
}
.quarter-progress {
  .progress-content {
    .active {
      @extend %common-css;
    }
  }
}
.half-progress {
  .progress-content {
    .gs-create {
      @extend %common-css;
    }
  }
}
.third-quarter-progress {
  .progress-content {
    .gs-create,
    .gs-integrate {
      @extend %common-css;
    }
  }
}

.three-ignite-progress {
  @media screen and #{$tablet-portrait} {
    position: absolute;
    bottom: 0;
    width: 48%;
  }
  @media screen and #{$large-tablet} {
    position: absolute;
    bottom: 0;
  }
  .progress-content {
    .gs-create,
    .gs-integrate {
      @extend %common-css;
    }
  }
}
.full-progress {
  @media screen and #{$tablet-portrait} {
    position: absolute;
    bottom: 0;
    width: 48%;
  }
  @media screen and #{$large-tablet} {
    position: relative !important;
  }
  @media screen and #{$large-tablet} {
    position: absolute;
    bottom: 0;
  }
  .progress-content {
    .gs-create,
    .gs-integrate,
    .gs-initialize {
      @extend %common-css;
    }
  }
}
