.IOS-ask-component {
  h3 {
    color: #9b9b9b;
    margin-bottom: 1px;
    padding: 0px 17px;
    line-height: 43px;
    width: 400px;
    span {
      color: #4a4a4a;
    }
  }
}
