@import "../../common/responsiveComponent";
.create-project-component,
.project-modal-content {
  h3 {
    color: #9b9b9b;
    width: 100%;
    br {
      display: none;
    }
    span {
      color: #4b4b4b;
    }
  }
  h6 {
    line-height: 20px;
    color: #4a4a4a;
    @media only screen and #{$tablet-portrait} {
      width: 23em;
    }
  }
  .project-card {
    background: #fff;
    box-shadow: -2px -1px 34px 7px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 33em;
    margin: 2em 0px;
    @media screen and #{$tablet-portrait} {
      width: 23em;
    }

    @media screen and #{$large-tablet} {
      width: 31em;
    }

    @media screen and #{$large-tablet} {
      width: 31em;
    }
  }
  .form-group {
    position: relative;
    min-height: 44px;
    margin-bottom: 0;
    input {
      border: 0px;
      font-size: 14px;
      color: #4a4a4a;
      padding: 0;
    }
    label {
      position: absolute;
      top: -13px;
      font-size: 14px;
      color: #484848;
      font-weight: 500;
    }
    svg {
      position: absolute;
      right: 0px;
      top: -7px;
      font-size: 26px;
      color: #484848;
      background-color: #f2f2f2;
      padding: 7px;
      border-radius: 100%;
      &.gs-filled {
        color: #045ec8;
        background-color: #dfe5f1 !important;
      }
    }
  }
  .form-group:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

.project-modal-content {
  // width: 27em;
  // margin: auto;
  .project-modal-create {
    width: 27em;
    margin: auto;
  }
  .project-card {
    width: 100% !important;
    // margin: 2em 0px;
    margin: 2em auto !important;
    @media screen and #{$tablet-portrait} {
      width: 100% !important;
    }
  }
  .pre-textbox {
    padding: 26px 20px 0px 20px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.project-container {
  .project-modal-content {
    z-index: 1001;
    position: absolute;
    background-color: white;
    min-height: 84vh;
    width: 75%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    margin: 4% 13%;
    box-shadow: 12px 12px 13px 0px rgba(0, 0, 0, 0.3);
    .modal-close-btn {
      color: #fff;
      background: #045ec8;
      box-shadow: 3px 3px 7px -1px #045ec8;
      opacity: 1;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      padding: 12px;
      position: absolute;
      left: -1.5%;
      top: 1%;
      outline: none;
    }
  }
}
