@import "../common/responsiveComponent";
$header-grey: #9b9b9b;
$header-coal: #4a4a4a;
$theme-color: #045ec8;

.customtext {
  text-align: center;
  padding: 2em 0;
  font-size: 14px;
  a {
    color: $theme-color;
    text-decoration: none;
  }
}

.signup {
  .geo-checkbox {
    // width: 100%;
    // float: left;
    // margin-top: 0px;
    // margin-bottom: 0px;
    // height: 31px;
    &.signup-label {
      width: 100%;
      label {
        font-size: 13px !important;
        a {
          color: $theme-color;
          text-decoration: none;
          display: inline-block;
        }
      }
    }
    .checkbox {
      input[type="checkbox"] {
        margin-top: 10px !important;
      }
    }
  }
}

.sublinks {
  .loginlabel {
    padding: 10px 0px;
    border: 1.2px solid #dedede;
    border-radius: 5px;
    font-weight: 700;
    color: #575757;
  }
}

// custom checkbox and checkmark

.geo-checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.forgotpass {
  // padding: 4px 0px !important;
  // width: 18%;
  // text-align: -webkit-right;
  a {
    color: #484848;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
  }
}

/* Hide the browser's default checkbox */
.geo-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.signup {
  .checkmark {
    position: absolute;
    top: 12px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 2px solid #b7b7b7;
    border-radius: 2px;
  }
  .pwd-captcha {
    position: relative;
  }
  iframe {
    position: relative;
    top: -7px;
  }
  .show-eye {
    position: absolute;
    bottom: 212px;
    right: 23px;
    svg {
      color: #b7b7b7;
    }
  }
  iframe {
    position: relative;
    top: 5px;
  }
}

.prelogin-left {
  height: 28em !important;
  .signup-card {
    height: -webkit-fill-available !important;
  }
}

/* On mouse-over, add a grey background color */
.geo-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
  background-color: transparent;
  border: 2px solid #b7b7b7;
}

/* When the checkbox is checked, add a blue background */
.geo-checkbox input:checked ~ .checkmark {
  background-color: $theme-color;
  border: 2px solid $theme-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.geo-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.geo-checkbox .checkmark:after {
  left: 3px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// ends here

.signin-component {
  .submit {
    font-weight: 700;
  }
  a {
    display: inline-block !important;
    padding: 0px !important;
  }
  .checkbox {
    label {
      font-size: 13px;
    }
  }
}

.geo-checkbox {
  .has-error {
    a {
      color: #d0021b !important;
    }
    .checkmark {
      border: 2px solid #d0021b;
    }
  }
}

.weak,
.okay,
.good,
.strong {
  margin-bottom: -17px;
  margin-top: -17px;
  display: block;
  text-align: right;
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  top: -25px;
  width: 50px;
  float: right;
}

.weak {
  color: #f56969;
}
.okay {
  color: #f7a407;
}

.good {
  color: #068cf7;
}
.strong {
  color: #7ac232;
}
.signup-component {
  width: 420px;
  position: relative;
  z-index: 9;
  .prelogin-card {
    background: #fff;
    box-shadow: -2px -1px 34px 7px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 99%;
    margin: 2em 0 1.4em 0;
    @media only screen and #{$tablet-portrait} {
      width: 23em;
    }
    .form-group {
      position: relative;
      min-height: 44px;
      margin-bottom: 0;
      input {
        border: 0;
        font-size: 13px;
        padding: 0;
        color: $header-coal !important;
        &::-webkit-input-placeholder {
          color: $header-grey;
          font-size: 13px;
        }
        &::-moz-placeholder {
          color: $header-grey;
          font-size: 13px;
        }
        &:-ms-input-placeholder {
          color: $header-grey;
          font-size: 13px;
        }
        &::placeholder {
          color: $header-grey;
          font-size: 13px;
        }
      }
      .form-control:focus {
        box-shadow: none;
      }
      .form-label {
        position: absolute;
        top: -13px;
        font-size: 14px;
        color: #484848;
        font-weight: 500;
      }
    }
    svg {
      position: absolute;
      right: 0;
      top: 1px;
      font-size: 26px;
      color: #484848;
      background-color: #f2f2f2;
      padding: 7px;
      border-radius: 100%;
      &.gs-filled {
        color: $theme-color;
        background-color: #dfe5f1 !important;
      }
    }
  }
  .gs-signup {
    margin-bottom: 10px;
    text-align: -webkit-right;
    @media only screen and #{$tablet-portrait} {
      width: 23em;
    }
  }
}

#custom-dropdown {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
