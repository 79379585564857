$phone: "(max-width: 480px)";
$phone-landscape: "(max-width: 480px), (orientation: landscape)";
$tablet-portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation : portrait)";
$tablet-landscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$large-tablet: "(min-width: 1023px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

.ReactNative-ask-component {
  h3 {
    color: #9b9b9b;
    margin-bottom: 1px;
    padding: 0px 17px;
    font-weight: 500;
    font-size: 1.65rem;
    line-height: 38px;
    span {
      color: #4a4a4a;
      font-size: 1.65rem;
      font-weight: 500;
    }
  }
}
.gs-codesection {
  .nav-tabs {
    a {
      font-size: 13px;
      color: #045ec8;
    }
  }
  code {
    font-size: 13px;
  }
}

.integrate-sdk-card {
  h3,
  h4 {
    padding: 0;
    font-size: 16px;
    color: #495057;
    padding-top: 10px;
  }
  ol {
    li {
      font-size: 14px;
      color: #4a4a4a;
    }
  }
}

@include respond-to($tablet-portrait) {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
  }
}

@include respond-to($large-tablet) {
  .integrate-box {
    height: 400px;
    overflow-y: scroll;
  }

  .integration-box-button {
    display: flex;
    margin-top: 10px;
  }
}
