.leaflet-container {
  height: calc(100vh - 0px);
  height: -webkit-calc(100vh - 0px);
  height: -o-calc(100vh - 0px);
  height: -moz-calc(100vh - 0px);
}

.map-component {
  width: 100%;
  left: 0px;
  height: auto;
  top: 7.15em;
  background: #b6bedc;
  z-index: 1;
  .leaflet-control-zoom {
    // display: none;
  }
}

.leaflet-container {
  height: 100vh;
  width: 100%;
  float: left;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.show-tooltip {
  visibility: hidden;
  opacity: 0;
  // opacity: 1;
  // z-index: 1000;
}

// .close-button-component {
//   padding: 5px;
// }

.close-button {
  svg {
    color: black;
    // z-index: 1000;
  }
}

.battery-status {
  display: flex;
  // justify-content: space-around;
}

.battery-icon {
  margin-left: 10px;
  svg {
    top: -3px;
  }
  .battery-rem {
    margin-left: -15px;
  }
}
