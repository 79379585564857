// responsive section
$phone: "(max-width: 480px)";
$phone-landscape: "(max-width: 480px), (orientation: landscape)";
$tablet-portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation : portrait)";
$tablet-landscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$large-tablet: "(min-width: 1023px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

$theme-color: #045ec8;
$primary-textcolor: #202124;
$white-bg: #ffffff;
$gs-gainsboro: #d8d8d8;

.integrate-sdk-component {
  .integrate-sdk-content {
    h3 {
      color: #9b9b9b;
      margin-bottom: 1px;
      padding: 0px 17px;
      font-weight: 500;
      font-size: 1.65rem;
      line-height: 38px;
      @include respond-to($tablet-portrait) {
        width: 14em;
      }
      span {
        color: #4a4a4a;
        font-size: 1.65rem;
        font-weight: 500;
      }
    }
    h4 {
      color: #4a4a4a;
      padding: 0px 17px;
      font-size: 1.65rem;
      font-weight: 500;
    }
    p {
      font-size: 13px;
      padding: 0px 17px;
      width: 33%;
      margin: 14px 0 0;
      @include respond-to($tablet-portrait) {
        width: 30em;
      }
    }
  }
  .gs-post-span {
    font-size: 13px;
    line-height: 20px;
  }

  @include respond-to($tablet-portrait) {
    .gs-post-span {
      display: block !important;
      min-width: 400px;
    }
  }

  .gs-post-buttons {
    display: block;
    // float: left;
    margin: 1em 0;
  }

  @include respond-to($tablet-portrait) {
    .gs-post-buttons {
      display: inline-block;
      margin: 1em 0;
      width: 100vh;
    }
  }
}
.gs-integrate {
  @include respond-to($tablet-portrait) {
    width: 23em;
  }
}
.postlogin-card {
  background: $white-bg;
  box-shadow: -2px -1px 34px 7px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 28em;
  margin: 2em 0 1em 0;
  @include respond-to($tablet-portrait) {
    width: 23em;
  }
  .sdkbox-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .post-sdkbox {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #f2f2f2;
    .integrate-sdk-card-component {
      cursor: pointer;
      &:hover {
        .gs-sdk-go {
          svg {
            fill: $theme-color;
          }
        }
      }
      .gs-sdk-icon {
        width: 20%;
        padding-right: 4%;
        svg {
          fill: $theme-color;
          border-radius: 100px;
          border: 1.12px solid $theme-color;
          padding: 5px;
          box-sizing: border-box;
          width: 27px;
          height: 27px;
        }
      }
      .gs-sdk-text {
        font-size: 13.1px;
        color: $primary-textcolor;
        .gs-textdark {
          font-weight: 500;
        }
      }
      .gs-sdk-go {
        float: right;
        svg {
          width: 13px;
          height: 13px;
          fill: $gs-gainsboro;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  .text-muted {
    color: red !important;
  }
}

@include respond-to($tablet-portrait) {
  .postlogin-card {
    width: 24em;
  }
}

.gs-rightdemoimage {
  display: none;
  position: fixed;
  width: 100%;
  overflow: hidden;
  right: -33px;
  z-index: 9;
  top: 2em;
  border-radius: 5px;
}
