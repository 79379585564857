@import "../../../../../components/common/button.scss";

// responsive section
$phone: "(max-width: 480px)";
$phone-landscape: "(max-width: 480px), (orientation: landscape)";
$tablet-portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation : portrait)";
$tablet-landscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$large-tablet: "(min-width: 1023px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

$theme-color: #045ec8;
// $primary-darkblue: #044895;
// $primary-disabled: #d8d8d8;
// $secondary-disabled-color: #56575e; //text color
// $white-bg: #ffffff;
// $secondary-hover-bg: rgba(38, 80, 162, 0.1);

.integrate-sdk-card {
  padding: 1em;
  background: #ffffff;
  box-shadow: -2px -1px 34px 7px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 50%;
  margin: 2em 0 1em 0;
  height: 31em;
  @include respond-to($tablet-portrait) {
    width: 23em;
    height: auto;
  }
  .gs-codesection {
    position: relative;

    code {
      // overflow-x: auto;
      // width: 100%;
      max-width: 100%;
      display: block;
      position: relative;
      top: 5px;
      font-size: 13px;

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: none;
        background-color: transparent;
        position: relative;
        top: 5px;
        height: 7px;
      }
      &:hover {
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
          border-radius: 7px;
          background-color: #fafafa;
          height: 7px;
          position: relative;
          top: 5px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #fafafa;
          height: 7px;
          position: relative;
          top: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 7px;
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
          background-color: #9b9b9b;
          height: 7px;
          position: relative;
          top: 5px;
        }
      }
    }
  }
  .icon-button {
    background: transparent;
    border: 0px;
    width: auto !important;
    margin: 0px;
    color: #9b9b9b;
    position: absolute;
    top: 7px;
    right: 7px;
    height: auto;
    transition: 0.2s;
    &:hover {
      color: $theme-color;
    }
  }
}
