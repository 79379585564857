// responsive section
@import "../../common/responsiveComponent";
.ignite-card-component {
  background: #fff;
  box-shadow: -2px -1px 34px 7px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 33em;
  margin: 2em 0px;
  padding-bottom: 17px;
  @media screen and #{$tablet-portrait} {
    width: 23em;
  }
  h3 {
    color: #9b9b9b;
    font-size: 30px;
    span {
      color: #4b4b4b;
    }
  }
  .gs-ignite-head {
    display: flex;
    padding: 31px 20px 0px 20px;
    justify-content: space-between;
  }
  .gs-ignite-descp {
    padding: 5px 20px 0px 20px;
    width: 29em;
    line-height: 20px;
    color: #4a4a4a;
    @media screen and #{$tablet-portrait} {
      width: 23em;
    }
  }
  a.disabled {
    pointer-events: none;
    cursor: default;
    color: grey;
    padding-left: 20px;
  }
  .gs-try-app {
    color: #045ec8;
    font-weight: 500;
    padding: 0px 0px 0 20px;
    text-decoration: none;
  }
  a::after {
    content: "    \2794";
    color: #045ec8;
    font-weight: 500;
    padding: 5px;
    // vertical-align: middle;
  }
}

.gs-ignite-initial-btn {
  font-size: 13px;
  padding: 13px;
  border-radius: 5px;
  text-decoration: none;
}
