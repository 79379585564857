// responsive section
$phone: "(max-width: 480px)";
$phone-landscape: "(max-width: 480px), (orientation: landscape)";
$tablet-portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation : portrait)";
$tablet-landscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$large-tablet: "(min-width: 1023px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

$header-grey: #9b9b9b;
$header-coal: #4a4a4a;
$theme-color: #045ec8;
$gs-gainsbro: #d8d8d8;

.android-ask-component {
  h3 {
    color: $header-grey;
    margin-bottom: 1px;
    padding: 0px 17px;
    font-size: 1.65rem;
    line-height: 38px;
    @include respond-to($tablet-portrait) {
      width: 16em;
    }
    span {
      padding: 0;
      color: $header-coal;
    }
    .sdk-ask-types {
      display: inline-block;
      padding: 0px 7px;
      color: $header-grey;
    }
  }
  h4 {
    color: $header-coal;
    padding: 0px 17px;
  }
  .postlogin-card {
    .gs-sdk-intergrate {
      border-bottom: 0px;
      @include respond-to($tablet-portrait) {
        padding: 26px 12px 0px 12px;
      }
    }
    .form-group {
      position: relative;
      min-height: 44px;
      margin-bottom: 0;
      .gs-sdk-icon {
        position: absolute;
        right: 0;
        top: 0;
        svg {
          fill: $theme-color;
          border-radius: 100px;
          border: 1.12px solid $theme-color;
          padding: 5px;
          box-sizing: border-box;
          width: 27px;
          height: 27px;
        }
      }
    }
    label.form-label {
      position: absolute;
      top: -13px;
      font-size: 14px;
      color: #484848;
      font-weight: 500;
    }
    input {
      border: 0px;
      font-size: 13px;
      color: $header-coal;
      padding: 0;
    }
    .gs-integration-method {
      p {
        font-size: 13px;
        font-weight: 500;
      }
      .gs-type {
        width: 100%;
        display: flex;
        flex-direction: row;
        .gs-integrate-type {
          width: 100%;
          text-align: center;
          border: 1px solid $gs-gainsbro;
          margin-right: 9px;
          border-radius: 3px;
          min-height: 107px;
          margin-bottom: 16px;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          span {
            font-size: 12px;
          }
          img {
            width: 43px;
            margin-bottom: 7px;
            margin-top: 4px;
          }
          &:last-child {
            margin-right: 0;
            img {
              margin-bottom: 3px;
              width: 50px;
              margin-top: 0px;
            }
          }
          &:hover {
            border: 1px solid #045ec8 !important;
          }
        }
      }
    }
  }
}

@include respond-to($tablet-portrait) {
  .android-ask-component {
    h3 {
      width: 500px;
    }
  }
}
